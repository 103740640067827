<template>
  <div>
    <v-container class="px-md-0">
      <v-skeleton-loader type="table" :loading="isLoading">
        <v-layout
          row
          wrap
          class="align-center my-2 px-10"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-flex md2>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold text-center">
                Balance<v-icon small class="pl-1">mdi-help-circle</v-icon>
              </p>
            </div>
          </v-flex>
          <v-flex md3>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold">Account</p>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold">Type</p>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold">Account Name</p>
            </div>
          </v-flex>

          <v-flex md2>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold">Updated</p>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center">
              <p class="mb-0 primary--text font-weight-bold">Action</p>
            </div>
          </v-flex>
        </v-layout>
        <div v-if="filteredAccounts && filteredAccounts.length > 0">
          <div v-for="(row, index) in displayedItems" :key="index">
            <AccountDataTableRow
              v-if="$vuetify.breakpoint.mdAndUp"
              :balance="row.balance"
              :type="row.type"
              :account="row.account"
              :accountName="row.account_name"
              :accountNumber="row.account_number || '00000'"
              :lastUpdated="row.last_access"
              :index="index"
            />
            <AccountsDataRowCards
              v-if="$vuetify.breakpoint.smAndDown"
              :balance="row.balance"
              :type="row.type"
              :account="row.account"
              :accountName="row.account_name"
              :accountNumber="row.account_number || '00000'"
              :lastUpdated="row.last_access"
            />
          </div>
        </div>
        <div class="d-flex justify-center align-center pt-6" v-else>
          <span class="text--primary text-h5 font-monospace"
            >No Data available</span
          >
        </div>
      </v-skeleton-loader>
      <!-- pagination component -->
      <div
        class="pagination__container px-8"
        v-if="filteredAccounts && filteredAccounts.length > 0"
      >
        <BasePagination
          :current-page="currentPage"
          :visible-pages="visiblePageValue"
          :total-pages="totalPages"
          @page-changed="handlePageChange"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AccountDataTableRow from "./accountDataTableRow.vue";
import AccountsDataRowCards from "./accountsDataRowCards.vue";

import BasePagination from "@/components/BasePagination.vue";
import pagination from "@/mixins/pagination.js";

export default {
  name: "accountDataPage",
  components: {
    AccountDataTableRow,
    AccountsDataRowCards,
    BasePagination,
  },
  mixins: [pagination],
  data() {
    return {
      isLoading: false,
      searchQuery: "",
      itemsPerPage: 7,
    };
  },

  computed: {
    ...mapGetters({
      allAccounts: "organizations/getOrganizationBankAccounts",
    }),

    // filter prop for accounts
    filteredAccounts: function () {
      if (!this.searchQuery) {
        return this.allAccounts;
      }
      const searchTerms = this.searchQuery.trim().toLowerCase().split(/\s+/);
      return this.allAccounts.filter((acct) => {
        return searchTerms.every((term) => {
          // const customer = receivable.customer;

          const account = acct?.customer?.toLowerCase() || "";
          const account_name = acct?.account_name?.toLowerCase() || "";
          const last_updated = acct?.last_access?.toLowerCase() || "";
          const account_type = acct?.account_type?.toLowerCase() || "";

          return (
            account.includes(term) ||
            account_name.includes(term) ||
            account_type.includes(term) ||
            last_updated.includes(term)
          );
        });
      });
    },

    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredAccounts.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (
        this.filteredAccounts &&
        this.filteredAccounts.length > this.itemsPerPage
      ) {
        let arraySubset = this.filteredAccounts?.slice(startIndex, endIndex);
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      } else {
        let arraySubset = this.filteredAccounts?.slice();
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      }
    },
    totalPages() {
      return Math.ceil(this.filteredAccounts.length / this.itemsPerPage);
    },
  },

  methods: {
    setSearchText(value) {
      this.searchQuery = value;

      console.log(this.searchQuery);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 6px;
}
</style>
