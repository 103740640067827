<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card class="px-7 py-6 my-1" style="cursor: pointer; transition: all 0.3s ease" :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''">
        <v-layout row wrap class="align-center">
          <v-flex md1>
            <div class="d-flex align-center">
              <v-chip :color="chipcolor" :text-color="textColor" class="chip__text" x-small>{{ directiontag }}</v-chip>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center pl-4">
              <span class="mb-0 text-center row__text" style="">{{
                amount
              }}</span>
            </div>
          </v-flex>

          <v-flex md2>
            <div class="d-flex align-center">
              <span class="mb-0 pl-10 row__text">{{ account }}</span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center">
              <span class="mb-0 row__text">{{ paymentRef }}</span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center px-1">
              <span class="mb-0 text-truncate row__text">{{ contact }}</span>
            </div>
          </v-flex>
          <v-flex md2>
            <div class="d-flex align-center justify-right">
              <span class="mb-0 text-right text-capitalize text-truncate row__text">{{ paymentDate }}</span>
            </div>
          </v-flex>
          <v-flex md1>
            <div class="d-flex align-center">
              <v-hover v-slot="{ hover }" v-show="directiontag == 'credit'">
                <v-btn  @click="$emit('view-details')" :style="{
                  'background-color': hover ? '#2bd5ae' : '',
                  border: hover ? 'none' : '',
                }" exact-path depressed outlined dark small color="#2BD5AE"
                  class="text-capitalize px-1 my-1 mr-1 rounded" style="color: var(--v-primary-base)">
                  Details
                </v-btn>
              </v-hover>
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: "paymentDataTableRow",
  props: {
    directiontag: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
    },
    amount: {
      type: [String, Number],
    },

    account: {
      type: String,
    },
    paymentRef: {
      type: String,
    },
    paymentDate: {
      type: String,
    },

    contact: {
      type: [String, Number],
    },
    status: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    statusIcon() {
      if (this.status === "pending") {
        return "#E3AA1C";
      } else if (this.status === "in review") {
        return "#2B99D5";
      } else return "#2BD5AE";
    },
    chipcolor() {
      if (this.directiontag === "credit") {
        return "#EEFCF9";
      } else return "#FDF9EF";
    },
    textColor() {
      if (this.directiontag === "credit") {
        return "#2BD5AE";
      } else return "#F7CA40";
    },
  },
};
</script>

<style scoped>
.row__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #596a73;
}

.chip__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
}

.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}
</style>
