var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"px-7 py-6 my-1",staticStyle:{"cursor":"pointer","transition":"all 0.3s ease"},attrs:{"elevation":hover ? 2 : 0,"color":_vm.index % 2 ? '#f6f6f6' : ''}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"chip__text",attrs:{"color":_vm.chipcolor,"text-color":_vm.textColor,"x-small":""}},[_vm._v(_vm._s(_vm.directiontag))])],1)]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center pl-4"},[_c('span',{staticClass:"mb-0 text-center row__text"},[_vm._v(_vm._s(_vm.amount))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 pl-10 row__text"},[_vm._v(_vm._s(_vm.account))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 row__text"},[_vm._v(_vm._s(_vm.paymentRef))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center px-1"},[_c('span',{staticClass:"mb-0 text-truncate row__text"},[_vm._v(_vm._s(_vm.contact))])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center justify-right"},[_c('span',{staticClass:"mb-0 text-right text-capitalize text-truncate row__text"},[_vm._v(_vm._s(_vm.paymentDate))])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-hover',{directives:[{name:"show",rawName:"v-show",value:(_vm.directiontag == 'credit'),expression:"directiontag == 'credit'"}],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-1 my-1 mr-1 rounded",staticStyle:{"color":"var(--v-primary-base)"},style:({
                'background-color': hover ? '#2bd5ae' : '',
                border: hover ? 'none' : '',
              }),attrs:{"exact-path":"","depressed":"","outlined":"","dark":"","small":"","color":"#2BD5AE"},on:{"click":function($event){return _vm.$emit('view-details')}}},[_vm._v(" Details ")])]}}],null,true)})],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }