<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <BaseTitleBar pageTitle="Payments" class="mt-sm-4 mt-md-0">
          <DropDownMenu
            btnText="New Payment"
            icon="file"
            height="54px"
            justify="right"
            :closeMenu="true"
          >
            <template #titleButton>
              <v-list-item-group>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in transactionMenus"
                    :key="index"
                    @click="handleMenuAction(index)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-item-group>
            </template>
          </DropDownMenu>
        </BaseTitleBar>

        <!-- transactions cards starts here -->
        <!-- <div class="
            container-wrapper
            d-sm-flex
            justify-center
            align-center
            py-6
            pb-md-0
            px-3
            pl-md-10
            pr-md-8
          ">
          <TransactionCards class="ml-md-5 mr-md-3 mr-sm-3" cardTitle="Available Balance" amount="45,040,350"
            accountSoure="banks">
          </TransactionCards>
          <TransactionCards class="mx-md-3 mx-sm-1" cardTitle="Payout Wallet" amount="3,230,000" accountSoure="wallet">
          </TransactionCards>
          <TransactionCards class="ml-md-3 mr-md-6 ml-sm-3" cardTitle="Expected revenue" amount="10,600,000"
            accountSoure="customers">
          </TransactionCards>
        </div> -->
        <!-- transactions cards ends here -->
        <v-container class="mt-md-12">
          <v-row class="mx-md-10 pa-0">
            <v-col class="d-flex flex-column align-center pr-md-0" cols="12">
              <v-card
                elevation="6"
                v-if="$vuetify.breakpoint.mdAndUp"
                flat
                width="100%"
                style="border-bottom: 1px solid rgba(127, 145, 155, 0.3)"
              >
                <v-tabs
                  slider-color="primary"
                  align-with-title
                  v-model="tab"
                  slider-size="4"
                >
                  <v-tab
                    class="mt-2"
                    v-for="item in transactionTabs"
                    :key="item.tab"
                    @change="handleTabChange"
                    style="
                      font-family: Inter;
                      font-style: normal;
                      font-weight: 800;
                      font-size: 12px;
                      line-height: 15px;
                      text-transform: uppercase;
                    "
                    >{{ item.tab }}</v-tab
                  >

                  <v-spacer></v-spacer>

                  <v-btn
                    v-if="isClicked"
                    @click="toggleSearch"
                    plain
                    class="text-black mt-1 pt-4"
                    style="
                      font-family: Inter;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 20px;
                      letter-spacing: 0.55px;
                      text-transform: uppercase;
                      color: #7f919b;
                    "
                  >
                    search
                    <v-icon small right class="pr-1"> mdi-magnify </v-icon>
                  </v-btn>
                  <v-expand-x-transition v-else>
                    <v-text-field
                      v-model="search"
                      @blur="isClicked = true && !search"
                      @input="searchDataTable"
                      class="seacrh-field mt-2 mr-2"
                      dense
                      clearable
                      autofocus
                      hide-details="true"
                      persistent-placeholder
                      placeholder="Search"
                      append-icon="mdi-magnify"
                      filled
                    >
                    </v-text-field>
                  </v-expand-x-transition>
                </v-tabs>
              </v-card>
              <v-card
                min-height="40px"
                width="100%"
                flat
                class="d-flex align-center py-4 py-md-0 px-3"
                style="background: rgba(127, 145, 155, 0.052607)"
              >
                <div
                  v-if="transactionTabs[tab].tab == 'Payments'"
                  class="d-block d-sm-flex align-center"
                  style="width: 100%"
                >
                  <div class="d-sm-flex align-center" :style="{ 'width': $vuetify.breakpoint.smAndUp ? '400px' : '' }">
                    <v-select
                      v-model="selectedDateTime"
                      :items="dateTimeFilter"
                      :disabled="allTransactions == null ? true : false"
                      item-text="text"
                      item-value="value"
                      placeholder="Date Filter"
                      outlined
                      background-color="#ffffff"
                      hide-details="auto"
                      dense
                      class="mb-4 mb-sm-0 pr-sm-4"
                      @change="handleDateFilter"
                    >
                    </v-select>
                    <v-select
                      v-model="selectedAccountType"
                      :disabled="allTransactions == null ? true : false"
                      item-text="text"
                      item-value="value"
                      :items="accountTypeFilter"
                      outlined
                      background-color="#ffffff"
                      hide-details="auto"
                      dense
                      @change="handleAccountTypeFilter"
                    ></v-select>
                  </div>
                  <v-spacer></v-spacer>
                  <download-csv
                    class="btn btn-default"
                    :data="filteredTransactions ? filteredTransactions : []"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        outlined
                        @click="alertCSVDownload"
                        color="primary"
                        class="my-4 export-btn mr-9 hover-btn"
                        :style="{
                          'background-color': hover ? '#2bd5ae' : '',
                          border: hover ? 'none' : '',
                        }"
                        elevation="2"
                        ><span class="material-icons pr-1"> import_export </span
                        ><span
                          class="primary--text"
                          style="
                            font-family: Inter;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 11px;
                            line-height: 12px;
                            text-align: center;
                            letter-spacing: 0.636364px;
                          "
                          >Export</span
                        >
                      </v-btn>
                    </v-hover>
                  </download-csv>
                </div>
                <div
                  v-if="transactionTabs[tab].tab == 'Reconcile'"
                  class="d-flex align-center"
                  style="width: 100%"
                >
                  <v-spacer></v-spacer>
                  <v-chip
                    x-small
                    color="#D9DEE1"
                    text-color="#525E6E"
                    class="chip__style float-right"
                    >{{ currency }}
                  </v-chip>
                </div>
              </v-card>
              <v-card width="100%" class="pb-12" elevation="6" flat>
                <component
                  v-bind:is="transactionTabs[tab].content"
                  class="ml-0"
                  ref="transactionTable"
                  @emitData="handleDataExport"
                  :pagination="pagination"
                >
                </component>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <!-- tabs for mobile devices -->
    <v-row
      class="px-0 mx-0"
      style="margin-top: 10vh"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-col class="ma-0 pa-0" cols="12">
        <v-bottom-navigation fixed class="pa-0 mx-0" dark>
          <v-tabs
            center-active
            class="ma-0"
            background-color="primary"
            v-model="tab"
          >
            <v-tab
              class="mt-2"
              v-for="item in transactionTabs"
              @change="handleTabChange"
              :key="item.tab"
              style="
                font-family: Inter;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                text-transform: uppercase;
              "
              >{{ item.tab }}</v-tab
            >
          </v-tabs>
        </v-bottom-navigation>
      </v-col>
    </v-row>
    <div>
      <v-navigation-drawer
        :hide-overlay="true"
        width="390px"
        v-model="transactionDrawer"
        right
        temporary
        app
        class="navigator"
      >
        <BankTransactions />
      </v-navigation-drawer>
    </div>
    <div>
      <v-navigation-drawer
        :hide-overlay="true"
        width="390px"
        v-model="statementDrawer"
        right
        temporary
        app
        class="navigator"
      >
        <BankStatement />
      </v-navigation-drawer>
    </div>
  </v-container>
</template>

<script>
import BaseTitleBar from "../../components/BaseTitleBar.vue";
import DropDownMenu from "../../includes/DropDownMenu.vue";
import paymentsDataPage from "./components/paymentsDataPage.vue";
import reconcileDataPage from "./components/reconcileDataPage.vue";
import statementsDataPage from "./components/statementsDataPage.vue";
import accountDataPage from "./components/accountDataPage.vue";
// import TransactionCards from "./components/transactionCards.vue";
import BankTransactions from "@/pages/transactions/BankTransactions.vue";
import BankStatement from "@/pages/transactions/BankStatement.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Transactions",
  props: ["pagination"],
  data() {
    return {
      currency: "NGN",
      filteredTransactions: null,
      transactionDrawer: false,
      statementDrawer: false,
      transactionMenus: [
        // {
        //   title: "Send / Transfer",
        // },
        // {
        //   title: "Bank Connection",
        // },
        {
          title: "Post Transaction",
        },
        {
          title: "Upload Statement",
        },
      ],

      isClicked: true,
      tab: 0,
      search: "",

      loading: false,
      dateTimeFilter: [
        { text: "Date Filter", value: 0 },
        { text: "Last 24 Hours", value: 1 },
        { text: "Last 7 Days", value: 2 },
        { text: "Last 30 Days", value: 3 },
        { text: "Last 45 Days", value: 4 },
      ],
      accountTypeFilter: [
        { text: "All Account", value: "all" },
        { text: "Inflow", value: "receivable" },
        { text: "Outflow", value: "payable" },
        { text: "Deposit", value: "deposit" },
        { text: "Fees", value: "fees" },
      ],
      selectedAccountType: null,
      selectedDateTime: 0,
    };
  },
  components: {
    BaseTitleBar,
    DropDownMenu,
    paymentsDataPage,
    // TransactionCards,
    reconcileDataPage,
    accountDataPage,
    BankTransactions,
    BankStatement,
    statementsDataPage,
  },
  methods: {
    // map Action for vuex ...
    ...mapActions({ showToast: "ui/showToast" }),

    toggleSearch() {
      this.isClicked = false;
    },
    searchDataTable(e) {
      this.$refs.transactionTable.setSearchText(e);
    },
    clickItem(index) {
      if (index == 2) {
        console.log(index);
      }
    },

    // call dispatch action at tab change

    handleTabChange() {
      if (this.transactionTabs[this.tab].tab === "Payments") {
        this.fetchAllBankAccounts();
      }
    },

    handleMenuAction(index) {
      if (this.transactionMenus[index].title == "Request Payment") {
        console.log(this.transactionMenus[index].title);
      } else if (this.transactionMenus[index].title == "Send / Transfer") {
        console.log(this.transactionMenus[index].title);
      } else if (this.transactionMenus[index].title == "Reconciliation") {
        console.log(this.transactionMenus[index].title);
      } else if (this.transactionMenus[index].title == "Bank Connection") {
        //method to launch the Mono app

        // console.log(process.env.VUE_APP_MONO_KEY);
        const options = {
          onSuccess: (response) => {
            //console.log(JSON.stringify(response));

            this.submitBankCode(response);

            // let resp =
            // console.log(JSON.stringify(resp, null, 2));
          },

          onClose: function () {
            console.log("user closed the widget.");
          },
        };
        this.$launchMono(options);
      } else if (this.transactionMenus[index].title == "Post Transaction") {
        // call the fetchAllBankAccounts dispatch
        this.fetchAllBankAccounts();

        this.transactionDrawer = true;
      } else if (this.transactionMenus[index].title == "Upload Statement") {
        this.statementDrawer = true;
      }
    },
    async submitBankCode(response) {
      const { data } = await this.$store.dispatch(
        "organizations/addBank",
        response
      );
      console.log(JSON.stringify(data, null, 2));
    },

    async handleDateFilter() {
      await this.$refs.transactionTable.filterByDate(this.selectedDateTime);
    },
    async handleAccountTypeFilter() {
      await this.$refs.transactionTable.filterByAccountType(
        this.selectedAccountType
      );
    },
    // handle the export of the emitted data
    handleDataExport(val) {
      this.filteredTransactions = val;
    },

    async fetchAllBankAccounts() {
      let organizationId = await this.organizationId;

      await this.$store.dispatch(
        "organizations/getOrgBankAccounts",
        organizationId
      );
      // await this.$store.dispatch("organizations/fetchBanks");
    },
    async getCustAndVendor() {
      await this.$store.dispatch("contacts/fetchAllCustomers");
      await this.$store.dispatch("contacts/fetchAllVendors");
      // await this.$store.dispatch("organizations/fetchBanks");
    },

    // toast for csv download
    alertCSVDownload() {
      if (this.allTransactions) {
        this.showToast({
          sclass: "success",
          show: true,
          message: "CSV downloaded!",
          timeout: 3000,
        });
      } else {
        this.showToast({
          sclass: "error",
          show: true,
          message: "No Data to Export!",
          timeout: 3000,
        });
      }
    },
  },
  computed: {
    //getter
    ...mapGetters({
      organizationToken: "organizations/OrganToken",
      allTransactions: "transactions/getAllTransactions",
      organizationId: "auth/organizationId",
    }),

    features() {
      const statements = this.$flags.find(
        (flag) => flag.key === "statements"
      )?.value;
      const reconciliation = this.$flags.find(
        (flag) => flag.key === "reconciliation"
      )?.value;
      return {
        statements,
        reconciliation,
      };
    },

    transactionTabs() {
      const tabs = [
        { tab: "Payments", content: "paymentsDataPage" },
        { tab: "Accounts", content: "accountDataPage" },

        ...(this.features.statements
          ? [{ tab: "Statements", content: "statementsDataPage" }]
          : []),
        ...(this.features.reconciliation
          ? [{ tab: "Reconcile", content: "reconcileDataPage" }]
          : []),
      ];

      return tabs;
    },
  },
  mounted() {
    this.selectedAccountType = this.accountTypeFilter[0].value;
  },

  watch: {
    organizationId: {
      handler() {
        this.getCustAndVendor();
      },
      immediate: true,
      deep: true,
    },

    //watch the organizationToken value and if its not null call the getAllPayables method
    organizationToken: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val != null) {
          this.$store.dispatch("payables/getAllPayables", val.data.hypn_id);
        }
      },
    },
  },
};
</script>

<style scoped>
.v-input .search-field .v-input__slot:before,
.v-input .search-field .v-input__slot:after {
  border: none !important;
  border-color: transparent !important;
}

.transTotal {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #7f919b;
}

.v-menu__content {
  box-shadow: none;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

th {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: var(--v-primary-base);
}

.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

#histogram {
  fill: #96ead7 !important;
}

.contact__record {
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  color: #7f919b;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.btn_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #d7a47b;
}

.chip__style {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
}

.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tab:first-child,
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
  margin-left: 12px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .contact__record {
    display: block;
  }
}
</style>
