// NigerianBanksMixin.js

export default {
  data() {
    return {
      nigerianBanks: [
        "Access Bank",
        "Citibank",
        "Ecobank Nigeria",
        "Fidelity Bank",
        "First Bank of Nigeria",
        "Guaranty Trust Bank",
        "Union Bank of Nigeria",
        "United Bank for Africa",
        "Zenith Bank",
        "AB Microfinance Bank",
        "Accion Microfinance Bank",
        "Addosser Microfinance Bank",
        "Baobab Microfinance Bank",
        "Cellulant Nigeria Limited",
        "Centre for Values in Leadership (CVL) Microfinance Bank",
        "Credit Direct Limited",
        "Daylight Microfinance Bank",
        "Fortis Microfinance Bank",
        "Infinity Microfinance Bank",
        "Jaiz Bank",
        "Kuda Microfinance Bank",
        "Lidya",
        "Mainstreet Microfinance Bank",
        "Mines",
        "Mkobo Microfinance Bank",
        "NPF Microfinance Bank",
        "NYSC Foundation Microfinance Bank",
        "Omoluabi Mortgage Bank",
        "Page Microfinance Bank",
        "Parallex Microfinance Bank",
        "Peculiar Ultimate Microfinance Bank",
        "Petra Microfinance Bank",
        "Prdential Mortgage Bank",
        "Quicklink Microfinance Bank",
        "Rand Merchant Bank",
        "RenMoney Microfinance Bank",
        "Royal Exchange Microfinance Bank",
        "Safety Microfinance Bank",
        "Stanford Microfinance Bank",
        "Sterling Alternative Finance",
        "Trust Microfinance Bank",
        "Trustfund Microfinance Bank",
        "UBA Microfinance Bank",
        "VFD Microfinance Bank",
        "Wema Bank",
        "Yes Microfinance Bank",
        "Zedvance Limited",
      ],
    };
  },
};
