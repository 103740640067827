<template>
  <div>
    <v-dialog v-model="dialog" max-width="574">
      <v-card color="#f7f7f7" class="rounded-lg">
        <v-form>
          <v-card-title
            style="background: #ffffff; border-radius: 8px 8px 0px 0px"
            class="px-md-8"
          >
            <img :src="require('@/assets/icons/tick-square.svg')" alt="" />
            <span class="modalTitle px-4">Add Bank Account</span>
            <v-spacer></v-spacer>
            <v-icon
              tag="button"
              @click="dialog = false"
              class="text-bolder"
              color="#596A73"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <div class="px-0 px-md-8 d-flex" style="background: #f3eee8">
            <v-tabs
              mobile-breakpoint="50"
              v-model="tab"
              background-color="#f3eee8"
              slider-size="4"
            >
              <v-tab v-for="item in addBankTab" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </div>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card style="background-color: #f7f7f7" flat>
                <div class="py-4 px-4 px-md-10">
                  <p class="py-3 ma-0 info__text">
                    Connect a bank account associated with your organization.
                    You can enter the bank details by filling the form below, or
                    use the dynamic
                    <span
                      class="mono_link pointer underline font-weight-bold"
                      @click="$emit('clicked')"
                      >connect a bank</span
                    >
                    option.
                  </p>
                  <div class="form__wrapper pa-8">
                    <v-form>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-label class="label" for="bank-select"
                            >Bank</v-label
                          >
                          <v-select
                            :rules="[(v) => !!v || 'Bank name is required']"
                            :items="nigerianBanks"
                            background-color="#ffffff"
                            outlined
                            hide-details="auto"
                            placeholder="select one"
                            dense
                            v-model="bankDetails.bankname"
                            append-icon="mdi-magnify"
                            :menu-props="{ searchable: true, lazy: true }"
                            id="bank-select"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-label class="label" for="account-number"
                            >Account Number</v-label
                          >
                          <v-text-field
                            outlined
                            v-model="bankDetails.accountnumber"
                            hide-details="auto"
                            background-color="#ffffff"
                            dense
                            :rules="[
                              (v) => !!v || 'Account number is required',
                              (v) =>
                                /^\d+$/.test(v) || 'Only numbers are allowed',
                            ]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-label class="label" for="account-number"
                            >Name on Account</v-label
                          >
                          <v-text-field
                            outlined
                            v-model="bankDetails.accountname"
                            hide-details="auto"
                            background-color="#ffffff"
                            placeholder="Organization Name"
                            dense
                            :rules="[(v) => !!v || 'Account name is required']"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </div>

                  <div class="d-flex justify-center justify-md-end py-2">
                    <v-btn
                      @click="dialog = false"
                      dark
                      width="121"
                      height="45"
                      color="#F4F5F6"
                      elevation="0"
                      class="
                        d-flex
                        justify-space-around
                        text-capitalize
                        mt-4
                        mb-10
                        mr-5
                      "
                    >
                      <v-icon small color="primary">mdi-close</v-icon>
                      <span class="primary--text">Cancel</span>
                    </v-btn>
                    <v-btn
                      link
                      @click="addBank"
                      dark
                      :loading="isAdding"
                      width="121"
                      height="45"
                      color="primary"
                      class="
                        d-flex
                        justify-space-around
                        text-capitalize
                        mt-4
                        mb-10
                      "
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                      Add
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BankList from "@/mixins/bankList.js";
export default {
  data() {
    return {
      dialog: false,
      isAdding: false,
      tab: null,
      addBankTab: ["Add Bank DEtails"],
      bankDetails: {
        accountname: "",
        bankname: "",
        accountnumber: "",
      },
    };
  },
  mixins: [BankList],
  methods: {
    show(value) {
      this.dialog = value;
    },

    addBank() {
      console.log(JSON.stringify(this.bankDetails, null, 2));
    },
  },
};
</script>
<style scoped>
.modalTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #19283d;
}

.info__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.45px;
  color: rgba(25, 40, 61, 0.8);
}

.mono_link {
  color: #5b67ba;
  text-decoration: underline;
  cursor: pointer;
}

.form__wrapper {
  background: #ffffff;
  border: 1px solid #d9dee1;
  border-radius: 4px;
}

.label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(25, 40, 61, 0.8);
}
</style>
