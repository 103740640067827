<template>
  <div>
    <div class="px-4 pt-8 px-md-8 pt-md-11" v-if="noReconcileData">
      <div class="title__header">Automate inflow and outflow tracking</div>
      <div class="d-md-flex align-center pt-6 pt-md-8 cards">
        <ReconcileSteps
          avatarColor="#F6D9CC"
          number="1"
          text="Connect a bank account"
        />
        <ReconcileSteps
          avatarColor="#B8ECE5"
          number="2"
          text="choose a corresponding statement"
        />
        <ReconcileSteps
          avatarColor="#CCD8F7"
          number="3"
          text="configure matching rules"
        />
        <ReconcileSteps
          avatarColor="#F7E8C2"
          number="4"
          text="automatic match and reconcile"
        />
      </div>
      <div class="text__info pt-6">
        Please note, only one Reconciliation can be open at a time, and a new
        Reconciliation cannot be created before the open Reconciliation process
        is completed.
      </div>
      <div class="py-6">
        <v-btn
          dark
          color="primary"
          class="text-capitalize py-md-6 px-md-8"
          style="box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24)"
          @click="showReconcileModal"
        >
          <img :src="require('@/assets/pbot_icons/file.svg')" />
          <span class="ml-2">New Reconciliation</span>
        </v-btn>
      </div>
      <div>
        <v-navigation-drawer
          width="360px"
          v-model="reconcileModal"
          right
          temporary
          app
          class="navigator"
        >
          <ReconcileNavigationDrawer class="content" />
        </v-navigation-drawer>
      </div>
    </div>
    <div v-else>
      <ReconcileWithData />
    </div>
  </div>
</template>

<script>
import ReconcileSteps from "./reconcileSteps.vue";
import ReconcileNavigationDrawer from "./ReconcileNavigationDrawer.vue";
import ReconcileWithData from "./reconcileWithData.vue";
export default {
  name: "reconcileDataPage",
  components: { ReconcileSteps, ReconcileNavigationDrawer, ReconcileWithData },
  data() {
    return {
      reconcileModal: false,
      noReconcileData: true,
    };
  },
  methods: {
    showReconcileModal() {
      this.reconcileModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.title__header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #19283d;
}

.cards {
  display: grid;
  gap: 1rem;
}

.text__info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.636364px;
  color: rgba(25, 40, 61, 0.8);
}
.content::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}
.content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
.v-naigation-drawer .content::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-base);
  border-radius: 10px;
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1224px) {
  .cards {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
