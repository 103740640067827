<template>
  <v-container class="px-0 cards">
    <v-skeleton-loader type="table" :loading="isLoading">
      <v-layout row wrap class="align-center my-2 px-8" v-if="$vuetify.breakpoint.mdAndUp">
        <v-flex md1>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold text-center">
              Direction
            </p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 pl-3 primary--text font-weight-bold">Amount</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 pl-9 primary--text font-weight-bold text-center">
              Account
            </p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Ref No.</p>
          </div>
        </v-flex>

        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 pl-1 primary--text font-weight-bold">Contact</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div>
            <p class="mb-0 pl-2 primary--text font-weight-bold">Date</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div>
            <p class="mb-0 pl-md-3 primary--text font-weight-bold">Action</p>
          </div>
        </v-flex>
      </v-layout>
      <div v-if="transactions && transactions.length > 0">
        <div v-for="(row, index) in displayedItems" :key="index">
          <!-- card rows that is only displayed in desktops and laptop screens -->
          <PaymentDataTableRow v-if="$vuetify.breakpoint.mdAndUp" :directiontag="row.direction" :account="row.type"
            :paymentRef="row.reference" :paymentDate="row.date | date" :contact="row.contact" :status="row.status"
            :amount="row.amount | currency(currencyNoSymbol)" :index="index" @view-details="openDetails(row.reference)" />
          <!-- these cards are only displayed in mobile and tablets -->
          <PaymentsDataRowCards v-if="$vuetify.breakpoint.smAndDown" :directiontag="row.direction" :account="row.type"
            :paymentRef="row.reference" :paymentDate="row.date | date" :contact="row.contact" :status="row.status"
            :amount="row.amount | currency(currencyNoSymbol)" @view-details="openDetails(row.reference)" />
        </div>
      </div>
      <div class="d-flex justify-center align-center py-10 mt-8" v-else>
        <span class="text--primary text-h5 font-monospace">No Data available</span>
      </div>
      <div class="pagination__container px-8" v-if="transactions && transactions.length > 0">
        <BasePagination :current-page="currentPage" :visible-pages="visiblePageValue" :total-pages="totalPages"
          @page-changed="handlePageChange" />
      </div>
      <!-- right sided vuetify navigation drawer -->
      <div>
        <v-navigation-drawer width="360px" temporary right v-model="detailsDrawer" :hide-overlay="true" app
          style="box-shadow: 0px 4px 16px rgba(192, 196, 202, 0.15)">
          <div class="d-flex justify-center align-center" style="height: 100vh; width: 100%" v-if="loadingDetails">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <paymentDetails @close="detailsDrawer = false"  v-else />
        </v-navigation-drawer>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import PaymentDataTableRow from "./paymentDataTableRow.vue";
import PaymentsDataRowCards from "./paymentsDataRowCards.vue";
import paymentDetails from "@/pages/transactions/paymentDetails.vue";
import currencyFilter from "@/mixins/currencyFilter";
import pagination from "@/mixins/pagination";
import BasePagination from "@/components/BasePagination.vue";
import { mapGetters } from "vuex";

export default {
  name: "paymentsDataPage",
  components: { PaymentDataTableRow, PaymentsDataRowCards, BasePagination, paymentDetails },

  data() {
    return {
      isLoading: false,
      // transactions: null,
      itemsPerPage: 10,
      searchQuery: "",
      detailsDrawer: false,
      loadingDetails: false,
    };
  },
  computed: {
    //getter
    ...mapGetters({
      organizationToken: "organizations/OrganToken",
      paymentData: "transactions/getAllTransactions",
    }),

    transactions() {
      return this.paymentData;
    },

    startValue() {
      const i = this.currentPage - 1;
      return i * this.itemsPerPage;
    },
    // No filter Data
    DefaultDateFilterData() {
      const now = new Date();
      return this.transactions?.filter((payment) => {
        const date = new Date(payment.date);
        return date > now;
      });
    },
    // filter array by date - 24Hours
    filter24Hours() {
      const now = new Date();
      const twentyFourHoursAgo = new Date(now - 24 * 60 * 60 * 1000);

      return this.transactions?.filter((payment) => {
        const date = new Date(payment.date);
        return date > twentyFourHoursAgo;
      });
    },
    // filter array by date - 7 days
    filterLast7Days() {
      const now = new Date();
      const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days ago in milliseconds
      return this.transactions?.filter((payment) => {
        const date = new Date(payment.date);
        return date > sevenDaysAgo;
      });
    },
    // filter array by date - 30 days
    filterLast30Days() {
      const now = new Date();
      const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago in milliseconds
      return this.transactions?.filter((payment) => {
        const date = new Date(payment.date);
        return date > thirtyDaysAgo;
      });
    },
    // filter array by date - 45 days
    filterLast45Days() {
      const now = new Date();
      const fortyFiveDaysAgo = new Date(
        now.getTime() - 45 * 24 * 60 * 60 * 1000
      ); // 45 days ago in milliseconds
      return this.transactions?.filter((payment) => {
        const date = new Date(payment.date);
        return date > fortyFiveDaysAgo;
      });
    },

    // filter array by account type - receivable
    filterByReceivable() {
      return this.transactions?.filter(
        (transaction) => transaction.type === "receivable"
      );
    },
    // filter array by account type - payable
    filterByPayable() {
      return this.transactions?.filter(
        (transaction) => transaction.type === "payable"
      );
    },
    // filter array by account type - deposit
    filterByDeposit() {
      return this.transactions?.filter(
        (transaction) => transaction.type === "deposit"
      );
    },
    // filter array by account type - fees
    filterByFees() {
      return this.transactions?.filter(
        (transaction) => transaction.type === "fees"
      );
    },

    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.transactions?.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (this.transactions && this.transactions?.length > this.itemsPerPage) {
        let arraySubset = this.transactions?.slice(startIndex, endIndex);
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      } else {
        let arraySubset = this.transactions?.slice();
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      }
    },

    totalPages() {
      return Math.ceil(this.transactions?.length / this.itemsPerPage);
    },
  },
  mixins: [currencyFilter, pagination],
  methods: {
    //vuex action to get all receivables
    async getAllTransactions() {
      this.isLoading = true;

      const dataObject = {
        organization_hypnID: this.$orgHypnId,
        start: 0,
        limit: -1,
      };

      try {
        this.$store.dispatch("transactions/getOrgTransactions", dataObject);
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
        }
      } finally {

        this.isLoading = false;

      }
    },
    // filter array by date
    filterByDate(val) {
      // console.log(val);
      switch (val) {
        case 0:
          this.$emit("emitData", this.DefaultDateFilterData);
          return this.DefaultDateFilterData;
        case 1:
          this.$emit("emitData", this.filter24Hours);
          return this.filter24Hours;
        case 2:
          this.$emit("emitData", this.filterLast7Days);
          return this.filterLast7Days;
        case 3:
          this.$emit("emitData", this.filterLast30Days);
          return this.filterLast30Days;
        case 4:
          this.$emit("emitData", this.filterLast45Days);
          return this.filterLast45Days;
      }
    },
    // filter array by account type
    filterByAccountType(val) {
      // console.log(val);
      if (val == "receivable") {
        this.$emit("emitData", this.filterByReceivable);
        return (this.transactions = this.filterByReceivable);
      } else if (val == "payable") {
        this.$emit("emitData", this.filterByPayable);
        return (this.transactions = this.filterByPayable);
      } else if (val == "deposit") {
        this.$emit("emitData", this.filterByDeposit);
        return (this.transactions = this.filterByDeposit);
      } else if (val == "fees") {
        this.$emit("emitData", this.filterByFees);
        return (this.transactions = this.filterByFees);
      }
    },

    // set searchQuery
    setSearchText(value) {
      this.searchQuery = value;

      // console.log(this.searchQuery);
    },

    // open details drawer
    async openDetails(paymentId) {
      this.detailsDrawer = true;
      this.loadingDetails = true;
      console.log(paymentId);
      const dataObject = {
        organization_hypnID: this.$orgHypnId,
        transactionId: paymentId
      };
      await this.$store.dispatch("transactions/getTransaction", dataObject);
      this.loadingDetails = false;
    },
  },

  mounted() {
    this.$emit("emitData", this.paymentData);
    if (this.transactions === null) {
      return (this.transactions = this.paymentData);
    }
    this.filterByDate(0);
  },
  watch: {
    "organizationToken.data.hypn_id": {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.getAllTransactions();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
}
</style>
