<template>
  <div>
    <div class="loader" v-if="isLoadingDetails">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div class="px-2 px-sm-6 px-md-8 py-8" v-else>
      <div>
        <p class="drawer_title">Bank Transaction Posting</p>
        <p class="drawer_info py-2">
          Manually post a transaction expected to happen in your bank account,
          this is useful for reconciliation.
        </p>
      </div>
      <div class="py-3">
        <v-select :items="filteredAccountArray" item-value="hypn_id" item-text="account"
          :menu-props="{ searchable: true, lazy: true }" prepend-inner-icon="mdi-magnify" :hide-details="true" outlined
          v-model="postTransactionObject.bank" class="hypn-input">
          <template #item="{ item }">
            <div class="item-wrapper py-2">
              <span>{{ item.account }}</span>
              <small class="grey--text text--lighten-1">{{ item.bank }}</small>
            </div>
          </template>
        </v-select>
        <p class="txt_btn" @click="handleAddBankModal">+ add Bank Account</p>
      </div>

      <!-- <pre>{{ combineCustAndVend }}</pre> -->
      <div class="py-1">
        <label>Money Direction</label>
        <v-select :items="moneyDirection" item-text="text" item-value="value" v-model="postTransactionObject.direction"
          :hide-details="true" outlined>
        </v-select>
      </div>
      <div class="py-1">
        <label>Payment Type</label>
        <v-select :items="paymentType" item-text="text" item-value="value" v-model="postTransactionObject.type"
          :hide-details="true" outlined>
        </v-select>
      </div>
      <div class="py-1">
        <label>Counter-party</label>
        <v-select :items="filteredCustomersAndVendors" prepend-inner-icon="mdi-magnify" item-value="id"
          item-text="company" :menu-props="{ searchable: true, lazy: true }" :hide-details="true" outlined
          v-model="postTransactionObject.entity" @change="updateEntityType">
        </v-select>
        <div class="d-flex align-center justify-space-between">
          <p class="txt_btn" @click="handleAddCustomer">+ add student</p>
          <p class="txt_btn" @click="handleInviteVendor">+ invite contractor</p>
        </div>
      </div>

      <div class="py-4">
        <hr class="dottedLine" />
      </div>
      <div class="py-1" v-if="postTransactionObject.direction === 'debit'">
        <label>Select Payable</label>
        <v-select :items="filteredPayableArr" prepend-inner-icon="mdi-magnify" item-value="id" item-text="vendor"
          :menu-props="{ searchable: true, lazy: true }" :hide-details="true" outlined
          v-model="postTransactionObject.tranx">
          <template #item="{ item }">
            <div class="item-wrapper py-2">
              <span>{{ item.vendor }}</span>
              <small class="grey--text text--lighten-1 text-truncate-left">{{
                item.ref
              }}</small>
            </div>
          </template>
        </v-select>
      </div>
      <div class="py-1">
        <label>Exact Amount</label>
        <v-text-field v-model="postTransactionObject.amount" hide-details="auto" outlined placeholder="0.00"
          @blur="formatAmount">
        </v-text-field>
      </div>

      <div class="py-1">
        <label>Narration</label>
        <v-text-field v-model="postTransactionObject.narration" hide-details="auto" outlined class="hypn-input">
        </v-text-field>
      </div>
      <v-row>
        <v-col cols="6">
          <v-menu ref="transMenu" v-model="transMenu" :close-on-content-click="true" transition="scale-transition" bottom
            offset-overflow min-width="auto" left>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field background-color="white" v-model="postTransactionObject.date" hide-details="auto"
                :rules="[(v) => !!v || 'Value is required']" v-bind="attrs" outlined v-on="on"
                class="text__input px-0 hypn-input" persistent-hint style="margin-top: 23px">
                <template>
                  <v-icon small class="input__icon ml-0" slot="prepend-inner" color="grey lighten-1">
                    mdi-calendar-month-outline
                  </v-icon>
                </template>
                <template #label> Trnx Date </template>
              </v-text-field>
            </template>
            <v-date-picker v-model="postTransactionObject.date" :hide-details="true" outlined no-title
              @input="transMenu = false" color="primary" :max="getTodayDate()">
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <div class="pt-4 pb-2">
        <hr class="dottedLine" />
      </div>
      <div class="py-1">
        <p class="section_title">Journal Entry</p>
        <v-row class="py-4 d-flex">
          <!-- <v-col>
                        <label>From Ledger</label>
                        <v-select :items="organizationInternalAccounts" item-value="hypn_id" item-text="account"
                            v-model="postTransactionObject.from" hide-details="auto" outlined >
                        </v-select>
                    </v-col> -->
          <v-col>
            <label>Post Account</label>
            <v-select :items="organizationInternalAccounts" item-value="hypn_id" item-text="account"
              v-model="postTransactionObject.to" hide-details="auto" outlined>
            </v-select>
          </v-col>
        </v-row>
        <!-- <div id="clickArea" @click="triggerFileInput" class="align-center justify-center">
                    <p class="docTitle">{{ transactionFile ? "" : 'Document' }}</p>
                    <P class="filename px-8">
                        {{ transactionFile ? transactionFile.name : 'Upload any supporting document for this transaction' }}
                    </P>
                </div>
                <v-file-input ref="fileInput" v-model="transactionFile" label="Select a file"
                    style="display: none;"></v-file-input> -->
      </div>
      <div class="py-5">
        <v-btn :color="disablePost ? '#d9dee1' : 'primary'" class="text-capitalize py-md-6 px-md-8"
          :disabled="disablePost" :style="disablePost ? '' : 'box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24)'
            " @click="handlePostTransaction">
          <v-icon> mdi-chevron-right </v-icon>
          <span class="ml-2">Post Transaction</span>
        </v-btn>
      </div>
    </div>
    <AddBankModal ref="AddBankDialog" @clicked="launchMono" />
    <AddVendor ref="AddVendor" />
    <AddCustomer ref="AddCustomer" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddBankModal from "@/components/addBankModal.vue";
import AddVendor from "@/includes/overlays/ContactsMenuModals/AddVendor.vue";
import AddCustomer from "@/includes/overlays/ContactsMenuModals/AddCustomer.vue";
export default {
  data() {
    return {
      isLoadingDetails: false,
      moneyDirection: [
        { text: "Receiving Money (Credit)", value: "credit" },
        { text: "Sending Money (Debit)", value: "debit" },
      ],
      paymentType: [
        { text: "Cheque", value: "cheque" },
        { text: "Transfer", value: "transfer" },
        { text: "Settlement", value: "settlement" },
        { text: "Refund", value: "refund" },
      ],

      transMenu: false,
      // TransactionDate: null,
      transactionFile: null,
      disablePost: false,
      postTransactionObject: {
        direction: "Sending Money (Debit)",
        type: "Transfer",
        narration: "",
        bank: "",
        entity: "",
        entity_type: "",
        from: "",
        to: "",
        amount: (0).toFixed(2),
        date: null,
        tranx: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      organizationBankAccounts: "organizations/getOrganizationBankAccounts",
      organizationInternalAccounts: "organizations/getAccountObjectForInternal",
      filteredAccountArray: "organizations/getAccountObject",
      combineCustAndVend: "contacts/getVendorAndCustomerCompanyName",
      organizationId: "auth/organizationId",
      organizationToken: "organizations/OrganToken",
      filteredPayableArr: "payables/getPayablesTranxArray",
    }),
    // // filter the combineCustAndVend array using the search value..
    filteredCustomersAndVendors() {
      let unSorted = this.combineCustAndVend
        ?.filter((item) => item.company !== null)
        .sort();
      return unSorted.sort((a, b) => a.company.localeCompare(b.company));
    },
  },
  methods: {
    // mapActions
    ...mapActions({ showToast: "ui/showToast" }),

    formatAmount() {
      let amount = this.postTransactionObject.amount;
      amount = amount.replace(/,/g, "");

      if (isNaN(parseFloat(amount))) {
        this.postTransactionObject.amount = "";
        return;
      }

      if (amount) {
        amount = this.addKobo(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.postTransactionObject.amount = amount;
      }
    },

    addKobo(target) {
      let val = target;

      if (isNaN(parseFloat(target))) {
        return val;
      }

      // const regex = /\.\d{2}$/;

      // check if target ends with .
      if (target.endsWith(".")) {
        const newV = target + "00";
        val = newV.trim();
      } else if (target.indexOf(".") == -1) {
        const newV = target + ".00";
        val = newV.trim();
      } else {
        // already has dot
        const newV = parseFloat(target).toFixed(2);
        val = newV.trim();
      }

      // count number of dot in target
      const dotCount = (val.match(/\./g) || []).length;
      if (dotCount > 1) {
        // remove everything after the first dot
        val = val.slice(0, val.indexOf(".") + 1);
      }

      return val;
    },

    // get the entity-type
    updateEntityType() {
      const selectedEntity = this.filteredCustomersAndVendors.find(
        (item) => item.id === this.postTransactionObject.entity
      );
      if (selectedEntity) {
        this.postTransactionObject.entity_type = selectedEntity.entity_type;
      } else {
        this.postTransactionObject.entity_type = "";
      }
    },

    // set max date

    getTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    triggerFileInput() {
      this.$refs.fileInput.$refs.input.click();
    },
    async handlePostTransaction() {
      // Remove commas and periods from the amount string
      const floatAmount = this.postTransactionObject.amount.replace(/,/g, "");

      // Convert the cleaned amount string to an integer
      this.postTransactionObject.amount = parseFloat(floatAmount);

      // const formData = new FormData();
      // formData.append('files.meta', this.transactionFile);
      // formData.append('data', JSON.stringify(this.postTransactionObject));

      // console.log(JSON.stringify(this.postTransactionObject, null, 2));

      const postData = {
        organizationId: this.organizationId,
        payload: this.postTransactionObject,
      };

      try {
        await this.$store.dispatch(
          "transactions/postABankTransactions",
          postData
        );

        // Display a success toast message if the dispatch is successful
        this.showToast({
          sclass: "success",
          show: true,
          message: "Transaction posted successfully!",
          timeout: 3000,
        });

        // disable the post btn
        this.disablePost = true;
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg,
          timeout: 3000,
        });
      } finally {
        const organHypnId = await this.organizationToken?.data?.hypn_id;

        const dataObject = {
          organization_hypnID: organHypnId,
          start: 0,
          limit: -1,
        };
        this.$store.dispatch("transactions/getOrgTransactions", dataObject);
      }
    },
    // open the add a bank modal
    handleAddBankModal() {
      this.$refs.AddBankDialog.show(true);
    },
    // call the add a customer logic...
    handleAddCustomer() {
      this.$refs.AddCustomer.show(true);
    },

    // call the invite a vendor logic...
    handleInviteVendor() {
      this.$refs.AddVendor.show(true);
    },

    //method to launch the Mono app
    launchMono() {
      const options = {
        onSuccess: (response) => {
          this.submitBankCode(response);
          this.$store.dispatch("organizations/fetchBanks");
        },
        onClose: function () {
          console.log("user closed the widget.");
        },
      };
      this.$launchMono(options);
    },
  },
  mounted() {
    this.isLoadingDetails = true;
    setTimeout(() => {
      this.isLoadingDetails = false;
    }, 3000);
  },
  components: {
    AddBankModal,
    AddVendor,
    AddCustomer,
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}

label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(25, 40, 61, 0.8);
}

.drawer_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #19283d;
}

.drawer_info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: rgba(25, 40, 61, 0.8);
}

.txt_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #19283d;
  cursor: pointer;
}

.dottedLine {
  border: 1px dashed rgba(25, 40, 61, 0.5);
}

.hypn-input>>> ::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 15px;
  color: #8f96a1;
}

.input__icon {
  line-height: 24px;
  padding-right: 1px;
  padding-left: 2px;
}

.v-text-field ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

.v-select ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

#clickArea {
  width: 100%;
  height: 126px;
  background-color: #ffffff;
  border: 1px dashed rgba(127, 145, 155, 0.551929);
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.docTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: rgba(25, 40, 61, 0.8);
}

.filename {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: rgba(25, 40, 61, 0.8);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 6px;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
}

.section_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.text-truncate-left {
  width: 45px;
  overflow: hidden;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
